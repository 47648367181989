// import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Button,
  Breadcrumbs,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import ChevronRightIcon from "../../icons/ChevronRight";
import { graphApi } from "../../api/graphapi";

const EverCredUsers = () => {
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getRequests())
  // }, []);

  const addUser = async () => {
    // TODO: figure out how to get userId for logged in user
    // const userId = "usr_TsQTReEKz861Yz5cVCPbQp";
    // console.log(userId);
    // const orgs = await graphApi.getOrganizations(userId);
    // console.log(orgs);
    // // find org unit we want . . .
    // var orgUnitId = "";
    // orgs.forEach((org, index) => {
    //   org.units.forEach((unit, unitIndex) => {
    //     if (orgUnitId.length === 0) {
    //       orgUnitId = unit.id;
    //       console.log(unit.id + " " + unit.name);
    //     }
    //   });
    // });
    // const newUserId = await graphApi.createEvercredUser(
    //   "Joe",
    //   "Test",
    //   "test@hpec.io"
    // );
    // console.log(newUserId);
    // await graphApi.addUserToOrgUnit(newUserId, orgUnitId);
    // console.log("user created and added to the org unit");
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: EverCred Users</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                EverCred Users
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  EverCred
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Users
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Button
              color="primary"
              type="submit"
              label="submit"
              variant="contained"
              onClick={async () => {
                await addUser();
              }}
            >
              Add User
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default EverCredUsers;
