import axios from 'axios'
import { api } from "../config"
import fileDownload from 'js-file-download';
import mime from 'mime-types'


class Data {
    
    async base64Data(dataId) {
        if (!dataId) return ''
        const token = localStorage.getItem('accessToken');
        try {
            const response = await axios({
                method: 'GET',
                url: `${api.dataapi}/${dataId}`,
                headers: {'authorization': `Bearer ${token}`},
                responseType: 'arraybuffer'
            })

            var data
            if (response.headers['content-type'].startsWith('application/json')) {
                data = await Buffer.from(response.data).toString()
            } else if (response.headers['content-type'].startsWith('application/pdf')){
                data = await Buffer.from(response.data)
            } else {
                data = await Buffer.from(response.data, 'binary').toString('base64')
            }

            return {data: data, ctype: response.headers['content-type']}
        } catch (e) {
            throw new Error(e)
        }
    }

    async downloadFile(dataId) {
        if (!dataId) return ''
        const token = localStorage.getItem('accessToken');
        try {
            const response = await axios({
                method: 'GET',
                url: `${api.dataapi}/${dataId}`,
                headers: {'authorization': `Bearer ${token}`},
                responseType: 'blob'
            })

            fileDownload(response.data, `${dataId}.${mime.extension(response.headers['content-type'])}`)
        } catch (e) {
            throw new Error(e)
        }
    }

}


export const dataApi = new Data();