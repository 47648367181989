import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { dataApi } from "../../../api/data";

const DataDetails = (props) => {
  const { images, data, viewData, ...other } = props;

  const renderFields = () => {
    return (
      <TableBody>
        {images.map((image) => {
          return (
            <TableRow key={image.id}>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  Image ({image.side})
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="textSecondary" variant="body2">
                  <Button
                    onClick={() => {
                      viewData(image.id);
                    }}
                  >
                    View
                  </Button>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="textSecondary" variant="body2">
                  <Button
                    onClick={() => {
                      dataApi.downloadFile(image.id);
                    }}
                  >
                    Download
                  </Button>
                </Typography>
              </TableCell>
            </TableRow>
          );
        })}
        {data.map((d) => {
          return (
            <TableRow key={d.id}>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  File: {d.name}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="textSecondary" variant="body2">
                  <Button
                    onClick={() => {
                      viewData(d.id);
                    }}
                  >
                    View
                  </Button>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="textSecondary" variant="body2">
                  <Button
                    onClick={() => {
                      dataApi.downloadFile(d.id);
                    }}
                  >
                    Download
                  </Button>
                </Typography>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  return (
    <Card {...other}>
      <CardHeader title="Data" />
      <Divider />
      <Table>{renderFields()}</Table>
    </Card>
  );
};

DataDetails.propTypes = {
  images: PropTypes.array,
  viewData: PropTypes.func,
};

export default DataDetails;
