import { createSlice } from '@reduxjs/toolkit';
import { graphApi } from '../api/graphapi';

const initialState = {
    requests: [],
    selectedRequestId: null
}

const slice = createSlice({
    name: 'verificationRequests',
    initialState,
    reducers: {
        getRequests(state, action) {
            state.requests = action.payload
        },
        selectRequest(state, action) {
            state.selectedRequestId = action.payload
        }
    }
})

export const { reducer } = slice;

export const getRequests = () => async (dispatch) => {
    const data = await graphApi.verifications();

    const users = {}
    const requests = data || []
    requests.forEach(element => {
        if (!(element.userId in users)) {
            users[element.userId] = { userId: element.userId, credentials: [element]}
        } else {
            users[element.userId].credentials.push(element)
        }
    })

    const complete = []
    for(const userId in users) {
        const profile = await graphApi.userProfile(userId)
        users[userId].profile = profile
        const user = await graphApi.userData(userId)
        users[userId].userData = user
        complete.push(users[userId])
    }

    
        
    dispatch(slice.actions.getRequests(complete))
}

export const getRequestsBatch = () => async (dispatch) => {
    const data = await graphApi.verifications();
    const requests = data || []
    const users = {}
    requests.forEach(element => {
        if (!(element.userId in users)) {
            users[element.userId] = { userId: element.userId, credentials: [element]}
        } else {
            users[element.userId].credentials.push(element)
        }
    })

    const res = await requestUserProfiles(users)
    dispatch(slice.actions.getRequests(res))
}

// TODO: change to batch graphql
function requestUserProfiles(users) {

    let promises = [];
    for(const userId in users) {
        promises.push(graphApi.userDataProfile(userId))
    }
    let complete = []
    return Promise.all(promises).then((values) => {
        for(let i = 0; i < values.length; i++) {
            let val = values[i];
            if (!val || !val.user || !val.profile) {
                continue
            }
            let user = users[val.user.id]
            user.userData = val.user
            user.profile = val.profile
            complete.push(user)
        }
        return complete
    });
}

export default slice;