import { CssBaseline, ThemeProvider } from "@mui/material";
import { createCustomTheme } from "./theme";
import { Helmet } from "react-helmet-async";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import useAuth from "./hooks/useAuth";
import useScrollReset from "./hooks/useScrollReset";
import SplashScreen from "./components/SplashScreen";

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

const App = () => {
  const content = useRoutes(routes);
  const auth = useAuth();

  useScrollReset();

  const theme = createCustomTheme({
    direction: "ltr",
    responsiveFontSizes: true,
    roundedCorners: true,
    theme: "LIGHT",
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Helmet>
        <title>HPEC Admin</title>
      </Helmet>
      {auth.isInitialized ? content : <SplashScreen />}
    </ThemeProvider>
  );
};

export default App;
