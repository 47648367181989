import { Navigate } from "react-router-dom";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import AuthGuard from "./components/AuthGuard";

// TODO: lazy loading from  route splitting stopped working on static build (npm run build)
//       can add that optimization instead of importing everything here
import DeleteUser from "./pages/dashboard/DeleteUser";
import Login from "./pages/authentication/Login";
import Overview from "./pages/dashboard/Overview";
import VerificationList from "./pages/dashboard/VerificationList";
import RequestorDetails from "./pages/dashboard/RequestorDetails";
import InviteList from "./pages/dashboard/InviteList";
import WalletRecovery from "./pages/dashboard/WalletRecovery";
import UnblockUser from "./pages/dashboard/UnblockUser";
import EverCredOrgs from "./pages/dashboard/EverCredOrgs";
import EverCredUsers from "./pages/dashboard/EverCredUsers";
import RunNotifyRemind from "./pages/dashboard/RunNotifyRemind";
import DeleteNotificationReminderHistory from "./pages/dashboard/DeleteNotificationReminderHistory";

const routes = [
  {
    path: "authentication",
    children: [
      {
        path: "login",
        element: <Login />,
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Overview />,
      },
      {
        path: "verifications",
        children: [
          {
            path: "",
            element: <VerificationList />,
          },
          {
            path: ":requestorId",
            element: <RequestorDetails />,
          },
          // {
          //   path: ':customerId/edit',
          //   element: <CustomerEdit />
          // }
        ],
      },
      {
        path: "invites",
        children: [
          {
            path: "",
            element: <InviteList />,
          },
        ],
      },
      {
        path: "walletrecovery",
        children: [
          {
            path: "",
            element: <WalletRecovery />,
          },
        ],
      },
      {
        path: "unblockuser",
        children: [
          {
            path: "",
            element: <UnblockUser />,
          },
        ],
      },
      {
        path: "evercredorgs",
        children: [
          {
            path: "",
            element: <EverCredOrgs />,
          },
        ],
      },
      {
        path: "evercredusers",
        children: [
          {
            path: "",
            element: <EverCredUsers />,
          },
        ],
      },
      {
        path: "runnotifyremind",
        children: [
          {
            path: "",
            element: <RunNotifyRemind />,
          },
        ],
      },
      {
        path: "deleteuser",
        children: [
          {
            path: "",
            element: <DeleteUser />,
          },
        ],
      },
      {
        path: "deletenotificationreminderhistory",
        children: [
          {
            path: "",
            element: <DeleteNotificationReminderHistory />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/dashboard" replace />,
  },
];

export default routes;
