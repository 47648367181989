import toast from "react-hot-toast";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { graphApi } from "../../api/graphapi";

const RunNotifyRemindForm = () => {
  return (
    <Formik
      initialValues={{
        triggerOption: "NORMAL",
        runLoopMaxSeconds: 0,
        filterUserEmail: "",
        filterType: "NONE",
        includeNotification: true,
        includeEmailReminder: false,
        includeTextReminder: false,
      }}
      validationSchema={Yup.object().shape({
        runLoopMaxSeconds: Yup.number()
          .typeError("you must specify a number")
          .min(0, "Min value 0.")
          .max(7200, "Max value 7200 (two hours)."),
        filterUserEmail: Yup.string().email("Must be a valid email").max(255),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          console.log("values", values);
          setSubmitting(true);

          var scaleTimeCheckDivisor = 0;
          var forceSend = false;
          var skipTimeCheck = false;
          if (values.triggerOption === "SCALE_MINUTES") {
            scaleTimeCheckDivisor = 1440; // convert days to minutes
          } else if (values.triggerOption === "SCALE_SECONDS") {
            scaleTimeCheckDivisor = 86400; // convert days to seconds
          } else if (values.triggerOption === "SKIP_TIME") {
            skipTimeCheck = true;
          } else if (values.triggerOption === "FORCE_SEND") {
            forceSend = true;
          }
          var filterType = values.filterType;
          if (filterType === "NONE") {
            filterType = "";
          }

          await graphApi.manuallyRunNotifyRemind(
            scaleTimeCheckDivisor,
            skipTimeCheck,
            forceSend,
            values.filterUserEmail,
            filterType,
            values.includeNotification,
            values.includeEmailReminder,
            values.includeTextReminder,
            values.runLoopMaxSeconds
          );

          setStatus({ success: true });
          setSubmitting(false);
          toast.success("Run launched!");
        } catch (err) {
          console.error(err);
          toast.error(`Something went wrong! (${err.message})`);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        handleReset,
      }) => (
        <form onSubmit={handleSubmit}>
          <Card style={{ display: "inline-block" }}>
            <Box sx={{ p: 3 }}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Select
                    error={Boolean(
                      touched.triggerOption && errors.triggerOption
                    )}
                    name="triggerOption"
                    value={values.triggerOption}
                    helperText={touched.triggerOption && errors.triggerOption}
                    label="Trigger Option"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  >
                    <MenuItem value={"NORMAL"}>
                      Normal time and condition checks
                    </MenuItem>
                    <MenuItem value={"SCALE_MINUTES"}>
                      Scale time check to minutes
                    </MenuItem>
                    <MenuItem value={"SCALE_SECONDS"}>
                      Scale time check to seconds
                    </MenuItem>
                    <MenuItem value={"SKIP_TIME"}>
                      Skip time check (still applies condition check)
                    </MenuItem>
                    <MenuItem value={"FORCE_SEND"}>Force Send</MenuItem>
                  </Select>
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(
                      touched.runLoopMaxSeconds && errors.runLoopMaxSeconds
                    )}
                    fullWidth
                    helperText={
                      touched.runLoopMaxSeconds && errors.runLoopMaxSeconds
                    }
                    label="Run loop max seconds"
                    name="runLoopMaxSeconds"
                    type="number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.runLoopMaxSeconds}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(
                      touched.filterUserEmail && errors.filterUserEmail
                    )}
                    fullWidth
                    helperText={
                      touched.filterUserEmail && errors.filterUserEmail
                    }
                    label="Filter user email"
                    name="filterUserEmail"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.filterUserEmail}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Select
                    error={Boolean(touched.filterType && errors.filterType)}
                    name="filterType"
                    value={values.filterType}
                    helperText={touched.filterType && errors.filterType}
                    label="Filter Type"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  >
                    <MenuItem value={"NONE"}>No Type Filter (Run All)</MenuItem>
                    <MenuItem value={"FINISH_PROFILE"}>Finish Profile</MenuItem>
                    <MenuItem value={"ENTER_NPI"}>Enter Npi</MenuItem>
                    <MenuItem value={"ENTER_ZIP"}>Enter Zip</MenuItem>
                    <MenuItem value={"WELCOME"}>Welcome</MenuItem>
                    <MenuItem value={"ACCOUNT_RECOVERY"}>
                      Account Recovery
                    </MenuItem>
                    <MenuItem value={"UPLOAD_CREDENTIAL"}>
                      Upload Credential
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.includeNotification}
                        name="includeNotification"
                        onChange={handleChange}
                      />
                    }
                    label="Include notification"
                    onBlur={handleBlur}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.includeEmailReminder}
                        name="includeEmailReminder"
                        onChange={handleChange}
                      />
                    }
                    label="Include email reminder"
                    onBlur={handleBlur}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.includeTextReminder}
                        name="includeTextReminder"
                        onChange={handleChange}
                      />
                    }
                    label="Include text reminder"
                    onBlur={handleBlur}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Box sx={{ mt: 5 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  label="submit"
                  variant="contained"
                >
                  Start Manual Run
                </Button>
                <Button
                  color="primary"
                  label="cancel"
                  variant="contained"
                  sx={{ ml: 3 }}
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </Box>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default RunNotifyRemindForm;
