import { useState, useRef } from "react";
import {
  Card,
  Grid,
  Box,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import PencilAlt from "../../../icons/PencilAlt";
import { Formik } from "formik";

const FieldDetails = (props) => {
  const { fields, schema, type, id, save, name, ...other } = props;

  const [editingFields, setEditingFields] = useState(false);

  const formRef = useRef();

  const saveCred = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const toggleEdit = () => {
    setEditingFields(!editingFields);
  };

  const combined = () => {
    const sFields = schema ? schema.fields : [];
    const toIgnore = ["credentialType"];

    const combined = [];
    const fcopy = [...fields];
    sFields.forEach((element) => {
      if (toIgnore.find((ig) => ig === element.name)) return;

      const field = fcopy.find((e) => e.name === element.name) || {
        notfound: true,
      };
      if (element.name === "firstName") {
        field.value = name.first;
      }
      if (element.name === "lastName") {
        field.value = name.last;
      }
      if (element.name === "middleName") {
        field.value = name.middle;
      }

      combined.push({
        label: element.label,
        name: element.name,
        value: field.value || "",
      });

      if (!field.notfound) {
        fcopy.splice(fcopy.indexOf(field), 1);
      }
    });
    fcopy.forEach((element) => {
      if (toIgnore.find((ig) => ig === element.name)) return;
      combined.push({
        name: element.name,
        label: element.name,
        value: element.value,
      });
    });

    return combined;
  };

  const formFields = () => {
    const fields = {};
    combined().forEach((e) => {
      console.log(e);
      fields[e.name] = e.value;
    });
    return fields;
  };

  const renderFields = () => {
    if (!editingFields) {
      return (
        <Table>
          <TableBody>
            {combined().map((f) => {
              return (
                <TableRow key={`${f.name}_${f.value}`}>
                  <TableCell>
                    <Typography color="textPrimary" variant="subtitle2">
                      {f.label}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary" variant="body2">
                      {f.value}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      );
    } else {
      return (
        <Formik
          innerRef={formRef}
          initialValues={{ ...formFields() }}
          onSubmit={async (
            values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            setSubmitting(true);

            const fields = [];
            Object.keys(values).forEach((k) => {
              const s = schema.fields.find((s) => s.label === k);
              if (s) {
                fields.push({ name: s.name, value: values[k] });
              } else {
                fields.push({ name: k, value: values[k] });
              }
            });

            await save(id, fields);
            setEditingFields(false);
            setSubmitting(false);
            resetForm();
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form>
              <Table>
                <TableBody>
                  {combined().map((k) => {
                    return (
                      <TableRow key={k.name}>
                        <TableCell>
                          <Typography color="textPrimary" variant="subtitle2">
                            {k.label}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(touched[k.name] && errors[k.name])}
                            fullWidth
                            helperText={touched[k.namee] && errors[k.name]}
                            label={k.label}
                            name={k.name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values[k.name]}
                            variant="outlined"
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </form>
          )}
        </Formik>
      );
    }
  };

  return (
    <Card {...other}>
      {/* <CardHeader title="Fields" /> */}
      <Grid container justifyContent="space-between">
        <Grid item>
          <Box sx={{ p: 2 }}>
            <Typography variant="h6">Fields</Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ p: 2 }}>
            <Typography>
              {editingFields ? (
                <>
                  <Button sx={{ mr: 2 }} onClick={saveCred}>
                    Save
                  </Button>
                  <Button onClick={toggleEdit}>Cancel</Button>
                </>
              ) : (
                type !== "GOVERNMENT_ID" && (
                  <IconButton onClick={toggleEdit}>
                    <PencilAlt />
                  </IconButton>
                )
              )}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider />
      {renderFields()}
    </Card>
  );
};

export default FieldDetails;
