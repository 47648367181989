import PropTypes from "prop-types";
import { format, parseISO } from "date-fns";
import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import Check from "../../../icons/Check";
import Label from "../../Label";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const CredentialDetails = (props) => {
  const {
    id,
    name,
    type,
    created,
    status,
    verifier,
    verified,
    verifyCallback,
    ...other
  } = props;

  const isVerified = status === "VERIFIED" || status === "ISSUED";

  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleOpen = () => {
    setConfirmOpen(true);
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };

  const verifyCredential = () => {
    verifyCallback(id);
    setConfirmOpen(false);
  };

  const formatName = () => {
    const combined = `${name.first} ${name.middle || ""} ${name.last}`;
    return `${combined} ${name.different ? "(different)" : ""}`;
  };

  return (
    <Card {...other}>
      <CardHeader title="Credential Details" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Id
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                {id}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Type
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                {type}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Name
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                {formatName()}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Created
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                {format(parseISO(created), "EEE MMM do, yyyy")}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Status
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                <Label color={isVerified ? "success" : "warning"}>
                  {status}
                </Label>
                {verified && (
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    sx={{ mt: 1 }}
                  >
                    {format(parseISO(verified), "EEE MMM do, yyyy")}
                  </Typography>
                )}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Verifier
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                {verifier}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Box
        sx={{
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
          p: 1,
        }}
      >
        <Button
          color="inherit"
          startIcon={<Check fontSize="small" />}
          sx={{ mt: 1 }}
          variant="text"
          onClick={handleOpen}
          disabled={isVerified}
        >
          Issue Verification
        </Button>
      </Box>
      <Dialog
        open={confirmOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Please Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Issue verification for {type}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={verifyCredential} color="primary">
            Yes
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

CredentialDetails.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  verifier: PropTypes.string.isRequired,
  verified: PropTypes.string,
  verifyCallback: PropTypes.func.isRequired,
};

export default CredentialDetails;
