// import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Button,
  Breadcrumbs,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import ChevronRightIcon from "../../icons/ChevronRight";
import { graphApi } from "../../api/graphapi";

const EverCredOrgs = () => {
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getRequests())
  // }, []);
  const createOrg = async () => {
    // TODO: figure out how to get userId for logged in user
    // const userId = "usr_TsQTReEKz861Yz5cVCPbQp";
    // console.log(userId);
    // const orgId = await graphApi.createOrganization(
    //   "JonTestOrg",
    //   "just a test"
    // );
    // console.log(orgId);
    // const orgUnitId = await graphApi.createOrganizationUnit(
    //   orgId,
    //   "JonTestOrgUnit",
    //   "just a test"
    // );
    // console.log(orgUnitId);
    // await graphApi.addUserToOrgUnit(userId, orgUnitId);
    // console.log("org and org unit added");
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: EverCred Orgs</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                EverCred Orgs
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  EverCred
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Orgs
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Button
              color="primary"
              type="submit"
              label="submit"
              variant="contained"
              onClick={async () => {
                await createOrg();
              }}
            >
              Create
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default EverCredOrgs;
