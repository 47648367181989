import PropTypes from "prop-types";
import { format, parseISO } from "date-fns";
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import Label from "../../Label";

const UserDetails = (props) => {
  const {
    id,
    name,
    displayName,
    email,
    emailVerified,
    phone,
    joined,
    ...other
  } = props;

  return (
    <Card {...other}>
      <CardHeader title="User Details" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Id
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                {id}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Name
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                {`${name.first} ${name.last}`}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Display Name
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                {displayName}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Email
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                {email}
              </Typography>
              <Label color={emailVerified ? "success" : "error"}>
                {emailVerified ? "Email verified" : "Email not verified"}
              </Label>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Phone
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                {phone}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="textPrimary" variant="subtitle2">
                Joined
              </Typography>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="body2">
                {joined ? format(parseISO(joined), "EEE MMM do, yyyy") : ""}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

UserDetails.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.object.isRequired,
  displayName: PropTypes.string,
  email: PropTypes.string.isRequired,
  emailVerified: PropTypes.string,
  joined: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
};

export default UserDetails;
