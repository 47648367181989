import { apolloClient } from "./apollo";
import { gql } from "@apollo/client";

class GraphAPI {
  async login({ username, password }) {
    try {
      const securityRole = "HPEC_ADMIN";
      const reply = await apolloClient.mutate({
        mutation: LOGIN,
        variables: { username, password, securityRole },
      });

      return reply.data.login;
    } catch (e) {
      throw new Error(e.message);
    }
  }

  async userProfile(userId) {
    try {
      const reply = await apolloClient.query({
        query: USER_PROFILE,
        variables: { userId },
      });

      return reply.data.profile;
    } catch (e) {
      throw new Error(e.message);
    }
  }

  async userData(userId) {
    try {
      const reply = await apolloClient.query({
        query: USER_DATA,
        variables: { userId },
      });

      return reply.data.user;
    } catch (e) {
      throw new Error(e.message);
    }
  }

  async userDataProfile(userId) {
    try {
      const reply = await apolloClient.query({
        query: USER_DATA_PROFILE,
        variables: { userId },
      });

      return reply.data;
    } catch (e) {
      console.log("User profile not found for user", userId, e.message)
    }
  }

  async verifications() {
    try {
      const reply = await apolloClient.query({
        query: VERIFICATIONS,
        fetchPolicy: "no-cache",
      });
      return reply.data.verificationRequests || [];
    } catch (e) {
      console.log({ e });
      throw new Error(e.message);
    }
  }

  async credentials(userId) {
    try {
      const reply = await apolloClient.query({
        query: CREDENTIALS,
        variables: { userId },
        fetchPolicy: "no-cache",
      });
      return reply.data.credentials || [];
    } catch (e) {
      throw new Error(e.message);
    }
  }

  async credentialDefinitions() {
    try {
      const reply = await apolloClient.query({
        query: CREDENTIAL_DEFINITIONS,
      });
      return reply.data.credentialDefinitions || [];
    } catch (e) {
      throw new Error(e.message);
    }
  }

  async verifyCredential(userId, credentialId) {
    try {
      await apolloClient.mutate({
        mutation: VERIFY_CREDENTIAL,
        variables: { userId, credentialId },
      });

      return true;
    } catch (e) {
      console.log({ e });
      throw new Error(e.message);
    }
  }

  async updateCredentialFields(userId, credential) {
    try {
      await apolloClient.mutate({
        mutation: SAVE_CREDENTIAL_UPLOAD,
        variables: {
          userId,
          id: credential.id,
          credential: {
            fields: credential.fields,
            type: credential.type,
            name: credential.name,
          },
        },
      });

      return true;
    } catch (e) {
      console.log({ e });
      throw new Error(e.message);
    }
  }

  async sendUserWalletRecovery(email, pin) {
    try {
      await apolloClient.mutate({
        mutation: SEND_WALLET_RECOVERY,
        variables: { email, pin },
      });

      return true;
    } catch (e) {
      console.log({ e });
      throw new Error(e.message);
    }
  }

  async logout() {
    apolloClient.resetStore();
  }

  async createEvercredUser(firstName, lastName, email) {
    try {
      const createReply = await apolloClient.mutate({
        mutation: CREATE_USER,
        variables: {
          input: {
            email,
            phone: "+15555555555",
            phoneValidationToken: "1111",
            firstName,
            lastName,
            terms: true,
            wasUserInvited: false,
          },
        },
      });
      const userId = createReply.data.createUser;
      return userId;
    } catch (e) {
      throw new Error(e.message);
    }
  }

  async createOrganization(name, description) {
    try {
      const reply = await apolloClient.mutate({
        mutation: CREATE_ORGANIZATION,
        variables: { name, description },
      });
      const orgId = reply.data.createOrganization;
      return orgId;
    } catch (e) {
      throw new Error(e.message);
    }
  }

  async createOrganizationUnit(organizationId, name, description) {
    try {
      const reply = await apolloClient.mutate({
        mutation: CREATE_ORGANIZATION_UNIT,
        variables: { input: { organizationId, name, description } },
      });
      const orgUnitId = reply.data.createOrganizationUnit;
      return orgUnitId;
    } catch (e) {
      throw new Error(e.message);
    }
  }

  async addUserToOrgUnit(userId, orgUnitId) {
    try {
      const reply = await apolloClient.mutate({
        mutation: ADD_USER_TO_ORG_UNIT,
        variables: { userId, orgUnit: orgUnitId },
      });
      return true;
    } catch (e) {
      throw new Error(e.message);
    }
  }

  async getOrganizations(userId) {
    try {
      const reply = await apolloClient.query({
        query: GET_ORGANIZATIONS,
        variables: { userId },
        fetchPolicy: "no-cache",
      });
      return reply.data.getOrganizations || [];
    } catch (e) {
      throw new Error(e.message);
    }
  }

  async activeNotifications(userId) {
    try {
      const reply = await apolloClient.query({
        query: ACTIVE_NOTIFICATIONS,
        variables: { userId },
        fetchPolicy: "no-cache",
      });
      return reply.data.activeNotifications || [];
    } catch (e) {
      throw new Error(e.message);
    }
  }

  async manuallyRunNotifyRemind(
    scaleTimeCheckDivisor,
    skipTimeCheck,
    forceSend,
    filterUserEmail,
    filterType,
    includeNotification,
    includeEmailReminder,
    includeTextReminder,
    runLoopMaxSeconds
  ) {
    try {
      await apolloClient.mutate({
        mutation: MANUALLY_RUN_NOTIFY_REMIND,
        variables: {
          input: {
            scaleTimeCheckDivisor,
            skipTimeCheck,
            forceSend,
            filterUserEmail,
            filterType,
            includeNotification,
            includeEmailReminder,
            includeTextReminder,
            runLoopMaxSeconds,
          },
        },
      });
      return true;
    } catch (e) {
      console.log(e);
      throw new Error(e.message);
    }
  }

  async deleteNotificationReminderHistory(email) {
    try {
      await apolloClient.mutate({
        mutation: DELETE_NOTIFICATION_REMINDER_HISTORY,
        variables: {
          input: {
            email,
          },
        },
      });
      return true;
    } catch (e) {
      console.log(e);
      throw new Error(e.message);
    }
  }

  async deleteUser(email) {
    try {
      await apolloClient.mutate({
        mutation: DELETE_USER,
        variables: {
          input: {
            email,
          },
        },
      });
      return true;
    } catch (e) {
      console.log(e);
      throw new Error(e.message);
    }
  }

  async updateNotificationsState(notificationIds, state) {
    try {
      await apolloClient.mutate({
        mutation: UPDATE_NOTIFICATIONS_STATE,
        variables: {
          input: {
            notificationIds,
            state,
          },
        },
      });
      return true;
    } catch (e) {
      console.log(e);
      throw new Error(e.message);
    }
  }

  async unblockUser(email, degree) {
    try {
      await apolloClient.mutate({
        mutation: UNBLOCK_USER,
        variables: {
          input: {
            email,
            degree,
          },
        },
      });
      return true;
    } catch (e) {
      console.log(e);
      throw new Error(e.message);
    }
  }
}

// Mutations

export const LOGIN = gql`
  mutation Login(
    $username: String!
    $password: String!
    $securityRole: String!
  ) {
    login(
      username: $username
      password: $password
      securityRole: $securityRole
    ) {
      jwt
      userId
    }
  }
`;

export const SAVE_CREDENTIAL_UPLOAD = gql`
  mutation SaveCredentialUpload(
    $id: String!
    $userId: String!
    $credential: CredentialInput!
  ) {
    saveCredentialUpload(
      input: { userId: $userId, credentialId: $id, credential: $credential }
    )
  }
`;

export const VERIFY_CREDENTIAL = gql`
  mutation VerifyCredential($userId: String!, $credentialId: String!) {
    verifyCredential(userId: $userId, credentialId: $credentialId)
  }
`;

export const SEND_WALLET_RECOVERY = gql`
  mutation SendUserWalletRecovery($email: String!, $pin: String!) {
    sendUserWalletRecovery(input: { email: $email, pin: $pin })
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser($input: CreateUser) {
    createUser(input: $input)
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput) {
    updateUser(input: $input)
  }
`;

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization($name: String!, $description: String!) {
    createOrganization(name: $name, description: $description)
  }
`;

export const CREATE_ORGANIZATION_UNIT = gql`
  mutation CreateOrganizationUnit($input: CreateOrgUnit) {
    createOrganizationUnit(input: $input)
  }
`;

export const ADD_USER_TO_ORG_UNIT = gql`
  mutation AddUserToOrgUnit($userId: String!, $orgUnit: String!) {
    addUserToOrgUnit(userId: $userId, orgUnit: $orgUnit)
  }
`;

export const MANUALLY_RUN_NOTIFY_REMIND = gql`
  mutation ManuallyRunNotifyRemind($input: ManuallyRunNotifyRemindInput!) {
    manuallyRunNotifyRemind(input: $input)
  }
`;

export const UPDATE_NOTIFICATIONS_STATE = gql`
  mutation UpdateNotificationsState($input: UpdateNotificationsStateInput!) {
    updateNotificationsState(input: $input)
  }
`;

export const UNBLOCK_USER = gql`
  mutation UnblockUser($input: UnblockUserInput!) {
    unblockUser(input: $input)
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input)
  }
`;

export const DELETE_NOTIFICATION_REMINDER_HISTORY = gql`
  mutation DeleteNotificationReminderHistory(
    $input: DeleteNotificationReminderHistoryInput!
  ) {
    deleteNotificationReminderHistory(input: $input)
  }
`;

// Query

export const CREDENTIAL_DEFINITIONS = gql`
  query CredentialDefinitions {
    credentialDefinitions {
      name
      type
      fields {
        name
        label
        required
      }
    }
  }
`;

export const VERIFICATIONS = gql`
  query Verivications {
    verificationRequests(pending: false) {
      id
      userId
      credentialId
      created
      completed
    }
  }
`;
export const USER_DATA = gql`
  query UserData($userId: String!) {
    user(userId: $userId) {
      email
      phone
      name {
        first
        last
      }
      emailVerified
      joined
    }
  }
`;

export const USER_PROFILE = gql`
  query UserProfile($userId: String!) {
    profile(userId: $userId) {
      displayName
      title
      avatar
    }
  }
`;

export const USER_DATA_PROFILE = gql`
  query UserProfile($userId: String!) {
    profile(userId: $userId) {
      displayName
      title
      avatar
    }
    user(userId: $userId) {
      id
      email
      phone
      name {
        first
        last
      }
      emailVerified
      joined
    }
  }
`;

export const CREDENTIALS = gql`
  query Credntials($userId: String!) {
    credentials(userId: $userId) {
      id
      name {
        different
        single
        prefix
        first
        last
        middle
        suffix
      }
      type
      images {
        side
        id
      }
      data {
        name
        id
      }
      fields {
        name
        value
      }
      created
      status
      verifier
      verified
      title
    }
  }
`;

export const ACTIVE_NOTIFICATIONS = gql`
  query ActiveNotifications($userId: String!) {
    activeNotifications(userId: $userId) {
      id
      created
      type
      state
      body
      title
      data {
        key
        value
      }
    }
  }
`;

export const GET_ORGANIZATIONS = gql`
  query GetOrganizations($userId: String!) {
    getOrganizations(userId: $userId) {
      organization {
        id
        created
        name
        description
      }
      units {
        id
        created
        name
        description
        logo
        did
        publicDid
      }
    }
  }
`;

export const graphApi = new GraphAPI();
