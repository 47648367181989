import PropTypes from "prop-types";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { Formik } from "formik";
import { Box, Button, Card, Grid, TextField } from "@mui/material";
import { graphApi } from "../../api/graphapi";

const ParticipantEditForm = (props) => {
  const { participant, orgUnit, projectId, ...other } = props;

  return (
    <Formik
      initialValues={{
        email: "",
        pin: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        pin: Yup.string()
          .required()
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(4, "Must be exactly 4 digits")
          .max(4, "Must be exactly 4 digits")
          .required("must provide a 4 digit pin"),
      })}
      onSubmit={async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
      ) => {
        try {
          console.log("values", values);
          setSubmitting(true);

          console.log(values);

          await graphApi.sendUserWalletRecovery(values.email, values.pin);
          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          toast.success("Recovery Sent!");
        } catch (err) {
          console.error(err);
          toast.error(`Something went wrong! (${err.message})`);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        handleReset,
      }) => (
        <form onSubmit={handleSubmit} {...other}>
          <Card style={{ display: "inline-block" }}>
            <Box sx={{ p: 3 }}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email address"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.pin && errors.pin)}
                    fullWidth
                    helperText={touched.pin && errors.pin}
                    label="Pin"
                    name="pin"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.pin}
                    variant="outlined"
                    inputProps={{ maxLength: 4 }}
                  />
                </Grid>
              </Grid>
              <Box sx={{ mt: 5 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  label="submit"
                  variant="contained"
                >
                  Send
                </Button>
                <Button
                  color="primary"
                  label="cancel"
                  variant="contained"
                  sx={{ ml: 3 }}
                  onClick={handleReset}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

ParticipantEditForm.propTypes = {
  participant: PropTypes.object.isRequired,
  orgUnit: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default ParticipantEditForm;
