import { Helmet } from "react-helmet-async";
import useAuth from "../../hooks/useAuth";
import { Box, Container, Grid, Typography } from "@mui/material";
// import {
//   OverviewInbox,
//   OverviewLatestTransactions,
//   OverviewPrivateWallet,
//   OverviewTotalBalance,
//   OverviewTotalTransactions,
//   OverviewWeeklyEarnings
// } from '../../components/dashboard/overview';
// import ArrowRightIcon from '../../icons/ArrowRight';
// import BriefcaseIcon from '../../icons/Briefcase';
// import DownloadIcon from '../../icons/Download';
// import ExternalLinkIcon from '../../icons/ExternalLink';
// import InformationCircleIcon from '../../icons/InformationCircle';
// import PlusIcon from '../../icons/Plus';
// import UsersIcon from '../../icons/Users';

const Overview = () => {
  const { user } = useAuth();

  const dateDisplay = () => {
    const ndate = new Date();
    const hours = ndate.getHours();
    return hours < 12
      ? "Good Morning"
      : hours < 18
      ? "Good Afternoon"
      : "Good Evening";
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: Overview</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid
              alignItems="center"
              container
              justifyContent="space-between"
              spacing={3}
              item
              xs={12}
            >
              <Grid item>
                <Typography color="textSecondary" variant="overline">
                  Overview
                </Typography>
                <Typography color="textPrimary" variant="h5">
                  {dateDisplay()}, {user.name.first}
                </Typography>
                <Typography color="textSecondary" variant="subtitle2">
                  Here&apos;s what&apos;s happening today
                </Typography>
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography color="textPrimary" variant="h5">
                Stats go here
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Overview;
