import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { format, parseISO } from "date-fns";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Card,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import ArrowRightIcon from "../../../icons/ArrowRight";
import SearchIcon from "../../../icons/Search";
import getInitials from "../../../utils/getInitials";
import Scrollbar from "../../Scrollbar";

const tabs = [
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "All",
    value: "all",
  },
  // {
  //   label: 'Accepts Marketing',
  //   value: 'hasAcceptedMarketing'
  // },
  // {
  //   label: 'Prospect',
  //   value: 'isProspect'
  // },
  // {
  //   label: 'Returning',
  //   value: 'isReturning'
  // }
];

const sortOptions = [
  {
    label: "Last update (newest)",
    value: "updatedAt|desc",
  },
  {
    label: "Last update (oldest)",
    value: "updatedAt|asc",
  },
  {
    label: "Number pending (highest)",
    value: "requests|desc",
  },
  {
    label: "Number pending (lowest)",
    value: "requests|asc",
  },
];

const applyFilters = (requests, query, filters) =>
  requests.filter((request) => {
    let matches = false;

    if (filters.pending || false) {
      request.credentials.forEach((r) => {
        if (!r.completed) {
          matches = true;
        }
      });
    } else {
      matches = true;
    }
    // if (query) {
    //   const properties = ['email', 'name'];
    //   let containsQuery = false;

    //   properties.forEach((property) => {
    //     if (customer[property].toLowerCase().includes(query.toLowerCase())) {
    //       containsQuery = true;
    //     }
    //   });

    //   if (!containsQuery) {
    //     matches = false;
    //   }
    // }

    // Object.keys(filters).forEach((key) => {
    //   const value = filters[key];

    //   if (value && customer[key] !== value) {
    //     matches = false;
    //   }
    // });

    return matches;
  });

const applyPagination = (requests, page, limit) =>
  requests.slice(page * limit, page * limit + limit);

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order, orderBy) =>
  order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

const applySort = (customers, sort) => {
  const [orderBy, order] = sort.split("|");
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = customers.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

const VerificationListTable = (props) => {
  const { requests, ...other } = props;
  const [currentTab, setCurrentTab] = useState("pending");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [query, setQuery] = useState("");
  const [avatars, setAvatars] = useState([]);
  const [sort, setSort] = useState(sortOptions[0].value);
  const [filters, setFilters] = useState({
    pending: true,
  });

  const handleTabsChange = (event, value) => {
    const updatedFilters = {
      ...filters,
      pending: null,
    };

    if (value !== "all") {
      updatedFilters[value] = true;
    }

    setFilters(updatedFilters);
    setCurrentTab(value);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const filteredRequests = applyFilters(requests, query, filters);
  const sortedRequests = applySort(filteredRequests, sort);
  const paginatedRequests = applyPagination(sortedRequests, page, limit);

  return (
    <Card {...other}>
      <Tabs
        indicatorColor="primary"
        onChange={handleTabsChange}
        scrollButtons="auto"
        textColor="primary"
        value={currentTab}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider />
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          m: -1,
          p: 2,
        }}
      >
        <Box
          sx={{
            m: 1,
            maxWidth: "100%",
            width: 500,
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={handleQueryChange}
            placeholder="Search"
            value={query}
            variant="outlined"
          />
        </Box>
        <Box
          sx={{
            m: 1,
            width: 240,
          }}
        >
          <TextField
            label="Sort By"
            name="sort"
            onChange={handleSortChange}
            select
            SelectProps={{ native: true }}
            value={sort}
            variant="outlined"
          >
            {sortOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </Box>
      </Box>
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Requests</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedRequests.map((request) => {
                return (
                  <TableRow hover key={request.userId}>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Avatar
                          src={avatars.find((a) => {
                            return a.id === request.profile.avatar
                              ? a.data
                              : "";
                          })}
                          sx={{
                            height: 42,
                            width: 42,
                          }}
                        >
                          {getInitials(
                            request.profile.displayName ||
                              `${request.userData.name.first} ${request.userData.name.last}`
                          )}
                        </Avatar>
                        <Box sx={{ ml: 1 }}>
                          <Link
                            color="inherit"
                            component={RouterLink}
                            to={`/dashboard/verifications/${request.userId}`}
                            variant="subtitle2"
                          >
                            {request.profile.displayName ||
                              `${request.userData.name.first} ${request.userData.name.last}`}
                          </Link>
                          <Typography color="textSecondary" variant="body2">
                            {request.userData.email}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {format(
                        parseISO(request.credentials[0].created),
                        "EEE MMM do, yyyy"
                      )}
                    </TableCell>
                    <TableCell>{request.credentials.length}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        component={RouterLink}
                        to={`/dashboard/verifications/${request.userId}`}
                      >
                        <ArrowRightIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={filteredRequests.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

VerificationListTable.propTypes = {
  requests: PropTypes.array.isRequired,
};

export default VerificationListTable;
