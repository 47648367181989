import toast from "react-hot-toast";
import * as Yup from "yup";
import { Formik } from "formik";
import { Box, Button, Card, Grid, TextField } from "@mui/material";
import { graphApi } from "../../api/graphapi";

const DeleteUserForm = () => {
  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email("Must be a valid email").max(255),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          console.log("values", values);
          setSubmitting(true);

          await graphApi.deleteUser(values.email);

          setStatus({ success: true });
          setSubmitting(false);
          toast.success("User Deleted!");
        } catch (err) {
          console.error(err);
          toast.error(`Something went wrong! (${err.message})`);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        handleReset,
      }) => (
        <form onSubmit={handleSubmit}>
          <Card style={{ display: "inline-block" }}>
            <Box sx={{ p: 3 }}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Box sx={{ mt: 5 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  label="submit"
                  variant="contained"
                >
                  Delete User
                </Button>
                <Button
                  color="primary"
                  label="cancel"
                  variant="contained"
                  sx={{ ml: 3 }}
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </Box>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default DeleteUserForm;
