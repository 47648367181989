import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Link,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import ReceiptIcon from "@mui/icons-material/Receipt";
import useAuth from "../../hooks/useAuth";
// import BriefcaseIcon from '../../icons/Briefcase';
// import CalendarIcon from '../../icons/Calendar';
// import ChartPieIcon from '../../icons/ChartPie';
import ChartSquareBarIcon from "../../icons/ChartSquareBar";
// import ChatAltIcon from '../../icons/ChatAlt';
// import ClipboardListIcon from '../../icons/ClipboardList';
// import FolderOpenIcon from '../../icons/FolderOpen';
// import MailIcon from '../../icons/Mail';
// import ShareIcon from '../../icons/Share';
// import ShoppingBagIcon from '../../icons/ShoppingBag';
// import ShoppingCartIcon from '../../icons/ShoppingCart';
// import UserIcon from '../../icons/User';
// import UsersIcon from '../../icons/Users';
import LockIcon from "../../icons/Lock";
import TrashIcon from "../../icons/Trash";
import Logo from "../Logo";
import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";

const sections = [
  {
    title: "General",
    items: [
      {
        title: "Overview",
        path: "/dashboard",
        icon: <ChartSquareBarIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "Credentials",
    items: [
      {
        title: "Verifcation",
        path: "/dashboard/verifications",
        icon: <ReceiptIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "Users",
    items: [
      {
        title: "Invites",
        path: "/dashboard/invites",
        icon: <ReceiptIcon fontSize="small" />,
      },
      {
        title: "Wallet Recovery",
        path: "/dashboard/walletrecovery",
        icon: <LockIcon fontSize="small" />,
      },
      {
        title: "Unblock User",
        path: "/dashboard/unblockuser",
        icon: <LockIcon fontSize="small" />,
      },
      {
        title: "Delete User",
        path: "/dashboard/deleteuser",
        icon: <TrashIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "EverCred",
    items: [
      {
        title: "Orgs",
        path: "/dashboard/evercredorgs",
        icon: <ReceiptIcon fontSize="small" />,
      },
      {
        title: "Users",
        path: "/dashboard/evercredusers",
        icon: <ReceiptIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "Notifications and Reminders",
    items: [
      {
        title: "Manual Run",
        path: "/dashboard/runnotifyremind",
        icon: <ReceiptIcon fontSize="small" />,
      },
      {
        title: "Delete History",
        path: "/dashboard/deletenotificationreminderhistory",
        icon: <TrashIcon fontSize="small" />,
      },
    ],
  },
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: "none",
              xs: "flex",
            },
            justifyContent: "center",
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: "center",
              backgroundColor: "background.default",
              borderRadius: 1,
              display: "flex",
              overflow: "hidden",
              p: 2,
            }}
          >
            <Avatar
              src={user.avatar}
              sx={{
                height: 48,
                width: 48,
              }}
            />
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {`${user.name.first} ${user.name.last}`}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {user.email}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 64px) !important",
            top: "64px !Important",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
