import toast from "react-hot-toast";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Card,
  Grid,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { graphApi } from "../../api/graphapi";

const UnblockUserForm = () => {
  return (
    <Formik
      initialValues={{
        email: "",
        degree: "MD",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
      })}
      onSubmit={async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
      ) => {
        try {
          setSubmitting(true);
          console.log(values);
          await graphApi.unblockUser(values.email, values.degree);
          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          toast.success("User unblocked!");
        } catch (err) {
          console.error(err);
          toast.error(`Something went wrong! (${err.message})`);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        handleReset,
      }) => (
        <form onSubmit={handleSubmit}>
          <Card style={{ display: "inline-block" }}>
            <Box sx={{ p: 3 }}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email address"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Select
                    error={Boolean(touched.degree && errors.degree)}
                    name="degree"
                    value={values.degree}
                    helperText={touched.degree && errors.degree}
                    label="Degree"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    required
                  >
                    <MenuItem value={"MD"}>Doctor of Medicine, MD</MenuItem>
                    <MenuItem value={"DO"}>Doctor of Osteopathy, DO</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Box sx={{ mt: 5 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  label="submit"
                  variant="contained"
                >
                  Send
                </Button>
                <Button
                  color="primary"
                  label="cancel"
                  variant="contained"
                  sx={{ ml: 3 }}
                  onClick={handleReset}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default UnblockUserForm;
