import { useCallback, useState, useEffect } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Document, Page } from "@react-pdf/renderer";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Grid,
  Link,
  Tab,
  Tabs,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import ReactJson from "react-json-view";
import { graphApi } from "../../api/graphapi";
import CredentialDetails from "../../components/dashboard/verifications/CredentialDetails";
import UserDetails from "../../components/dashboard/verifications/UserDetails";
import FieldDetails from "../../components/dashboard/verifications/FieldDetails";
import DataDetails from "../../components/dashboard/verifications/DataDetails";
import useMounted from "../../hooks/useMounted";
import ChevronRightIcon from "../../icons/ChevronRight";
import { dataApi } from "../../api/data";

const RequestorDetails = () => {
  const mounted = useMounted();
  const [credentials, setCredentials] = useState([]);
  const [credentialDefitions, setCredentialDefinitions] = useState([]);
  const [requestor, setRequestor] = useState(null);
  const [currentTab, setCurrentTab] = useState("");
  const [fileOpen, setFileOpen] = useState(false);
  const [fileData, setFileData] = useState({ data: "", ctype: "" });
  const [pdfNumPages, setPdfNumPages] = useState(null);

  const { requestorId } = useParams();

  const saveCredential = async (id, fields) => {
    console.log(fields);
    try {
      const cred = credentials.find((e) => e.id === id);
      const name = {
        first: cred.name.first,
        last: cred.name.last,
        middle: cred.name.middle,
        prefix: cred.name.prefix,
        suffix: cred.name.suffix,
        single: cred.name.single,
      };
      const fName = fields.findIndex((e) => e.name === "firstName");
      if (fName !== -1) {
        name.first = fields[fName].value;
        fields.splice(fName, 1);
      }
      const lName = fields.findIndex((e) => e.name === "lastName");
      if (lName !== -1) {
        name.last = fields[lName].value;
        fields.splice(lName, 1);
      }
      const mName = fields.findIndex((e) => e.name === "middleName");
      if (mName !== -1) {
        name.middle = fields[mName].value;
        fields.splice(mName, 1);
      }
      cred.name = name;
      cred.fields = fields;
      await graphApi.updateCredentialFields(requestorId, cred);
      const data = await graphApi.credentials(requestorId);
      setCredentials(data);
    } catch (e) {
      console.log(e);
    }
  };

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const handleClose = () => {
    setFileOpen(false);
  };

  const viewData = async (imageId) => {
    try {
      setFileData({ data: "" });
      const idata = await dataApi.base64Data(imageId);
      setFileData(idata);
      setFileOpen(true);
    } catch (e) {
      console.log(e);
    }
  };

  const onPdfLoadSuccess = ({ numPages: nextNumPages }) => {
    setPdfNumPages(nextNumPages);
  };

  const getRequestor = useCallback(async () => {
    try {
      const user = await graphApi.userData(requestorId);
      const profile = await graphApi.userProfile(requestorId);

      const combined = { ...user, displayName: profile.displayName };
      if (mounted.current) {
        setRequestor(combined);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted, requestorId]);

  const getCredentials = useCallback(async () => {
    try {
      const credDef = await graphApi.credentialDefinitions();
      setCredentialDefinitions(credDef);

      const data = await graphApi.credentials(requestorId);

      if (mounted.current) {
        setCredentials(data);
        setCurrentTab(data[0].id);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted, requestorId]);

  const definitionFor = (credtype) => {
    return credentialDefitions.find((e) => e.type === credtype);
  };

  useEffect(() => {
    getRequestor();
    getCredentials();
  }, [getRequestor, getCredentials]);

  const verifyCredential = async (credentialId) => {
    console.log("Verify", credentialId);
    try {
      await graphApi.verifyCredential(requestorId, credentialId);
      const data = await graphApi.credentials(requestorId);
      setCredentials(data);
    } catch (e) {
      console.log(e);
    }
  };

  if (!requestor) {
    return null;
  }

  const selectedCredential = credentials.find((c) => c.id === currentTab);

  return (
    <>
      <Helmet>
        <title>Dashboard: Verification Details</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                {`${requestor.name.first} ${requestor.name.last}`}
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard/verifications"
                  variant="subtitle2"
                >
                  Credentials
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Verifications
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            {credentials.length > 0 && currentTab && (
              <Tabs
                indicatorColor="primary"
                onChange={handleTabsChange}
                scrollButtons="auto"
                textColor="primary"
                value={currentTab}
                variant="scrollable"
              >
                {credentials.map((tab) => (
                  <Tab key={tab.id} label={tab.type} value={tab.id} />
                ))}
              </Tabs>
            )}
          </Box>
          <Divider />
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={3}>
              <Grid item lg={6} md={6} xl={6} xs={12}>
                {selectedCredential && (
                  <CredentialDetails
                    id={selectedCredential.id}
                    name={selectedCredential.name}
                    created={selectedCredential.created}
                    type={selectedCredential.type}
                    status={selectedCredential.status}
                    verifier={selectedCredential.verifier}
                    verifyCallback={verifyCredential}
                    verified={selectedCredential.verified}
                    nameDifferent={selectedCredential.nameDifferent}
                  />
                )}
              </Grid>
              <Grid item lg={6} md={6} xl={6} xs={12}>
                {selectedCredential && (
                  <UserDetails
                    id={requestorId}
                    name={requestor.name}
                    displayName={requestor.displayName}
                    email={requestor.email}
                    emailVerified={requestor.emailVerified}
                    phone={requestor.phone}
                    joined={requestor.joined}
                  />
                )}
              </Grid>
              <Grid item lg={6} md={6} xl={6} xs={12}>
                {selectedCredential && (
                  <FieldDetails
                    schema={definitionFor(selectedCredential.type)}
                    fields={selectedCredential.fields || []}
                    type={selectedCredential.type}
                    id={selectedCredential.id}
                    save={saveCredential}
                    name={selectedCredential.name || {}}
                  />
                )}
              </Grid>
              <Grid item lg={6} md={6} xl={6} xs={12}>
                {selectedCredential && (
                  <DataDetails
                    viewData={viewData}
                    images={selectedCredential.images || []}
                    data={selectedCredential.data || []}
                  />
                )}
              </Grid>
            </Grid>
            <Dialog
              open={fileOpen}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth="true"
              maxWidth={"md"}
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {fileData.data.length > 0 &&
                    fileData.ctype.startsWith("image") && (
                      <img
                        src={`data:${fileData.ctype};base64,${fileData.data}`}
                        style={{ maxWidth: "500px" }}
                        alt="credential data"
                      />
                    )}
                  {fileData.data.length > 0 &&
                    fileData.ctype.startsWith("application/json") && (
                      <ReactJson
                        src={JSON.parse(fileData.data)}
                        name={false}
                        enableClipboard={false}
                        displayObjectSize={false}
                        displayDataTypes={false}
                      />
                    )}
                  {fileData.data.length > 0 &&
                    fileData.ctype.startsWith("application/pdf") && (
                      <Document
                        file={{ data: fileData.data }}
                        sx={{ maxWidth: "500px" }}
                        onLoadSuccess={onPdfLoadSuccess}
                      >
                        {Array.from(new Array(pdfNumPages), (el, index) => (
                          <Page
                            pageNumber={index + 1}
                            key={`page_${index + 1}`}
                          />
                        ))}
                      </Document>
                    )}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default RequestorDetails;
