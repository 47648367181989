export const api = {
  graphapi: process.env.REACT_APP_GRAPH_API,
  dataapi: process.env.REACT_APP_DATA_API,
  // graphapi: "http://127.0.0.1:3000/graphql",
  // dataapi: "http://127.0.0.1:3000/data",
  // graphapi: "https://staging.api.hpec.io/graphql",
  // dataapi: "https://staging.api.hpec.io/data",
  // graphapi: 'https://api.hpec.io/graphql',
  // dataapi: 'https://api.hpec.io/data',
};
